import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AuthLoginInput, SigninGQL, User } from '../../services/hobbyts.service';
import { RefreshService } from '../refresh.service';
import { setAccessToken } from './login/auth.utils';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private apollo: Apollo,
    private signGql: SigninGQL,
    private refreshService: RefreshService,
    private storageService: StorageService
  ) {}

  public login(telegramUserObj: any, returnUrl = '/home'): void {
    // telegram callback
    const loginInput: AuthLoginInput = {
      telegramId: telegramUserObj.id || '',
      nickName: telegramUserObj.username || '',
      firstName: telegramUserObj.first_name || '',
      lastName: telegramUserObj.last_name || '',
      telegramAccessHash: telegramUserObj.hash || '',
      photoUrl: telegramUserObj.photo_url || '',
      role: 'organizer'
    };
    // ToDo: work with store cache
    this.apollo
      .mutate({
        mutation: this.signGql.document,
        variables: {
          loginInput
        }
      })
      .subscribe({
        next: ({ data }) => {
          const userObj = {
            firstName: (data as any).login.user.firstName,
            lastName: (data as any).login.user.lastName,
            nickName: (data as any).login.user.nickName,
            role: (data as any).login.user.role
          };
          // ToDo: implement indexedDB (for offline usage) work on PWA
          setAccessToken(this.storageService, (data as any).login.token);
          this.storageService.setValue('store-userId', (data as any).login.user.id);
          this.storageService.setValue('store-userObj', JSON.stringify(userObj));
          this.refreshService.isAuthenticated$.next(true);
          this.router.navigateByUrl(returnUrl);
        },
        error: (error) => {
          console.error('there was an error sending the query', error);
        }
      });
  }

  public logout(): void {
    setAccessToken(this.storageService, '');
    localStorage.removeItem('store-userId');
    localStorage.removeItem('store-userObj');
    this.refreshService.isAuthenticated$.next(false);
    this.router.navigate(['/']);
  }

  public goToLoginPage(): void {
    this.router.navigate(['login'], { queryParams: { returnUrl: 'home' } });
  }

  public getCurrentUser(): User | null {
    const userString = this.storageService.getValue('store-userObj');
    if (userString) {
      return JSON.parse(userString);
    }
    return null;
  }
}
