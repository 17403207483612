import { StorageService } from "src/app/services/storage.service";

export function getAccessToken(storageService: StorageService): string {
  return storageService.getValue('store-accessToken') || '';
}

export function setAccessToken(storageService: StorageService, accessToken: string): void {
  if (!accessToken) {
    localStorage.removeItem('store-accessToken');
    return;
  }
  storageService.setValue('store-accessToken', accessToken);
}
