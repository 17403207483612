import { Injectable, Injector } from '@angular/core';
import { NewAccessTokenGQL } from '../services/hobbyts.service';
import { getAccessToken, setAccessToken } from './auth/login/auth.utils';
import { BehaviorSubject, Observable, catchError, map, tap, throwError } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  constructor(private injector: Injector, private storageService: StorageService) {
    if (getAccessToken(this.storageService)) {
      this.isAuthenticated$.next(true);
    } else {
      this.isAuthenticated$.next(false);
    }
  }

  public fetchAccessToken(): Observable<any> {
    const newAccessTokenMutation = this.injector.get<NewAccessTokenGQL>(NewAccessTokenGQL);

    return newAccessTokenMutation.mutate().pipe(
      // map(({ data }) => data?.newAccessToken ?? ''),
      map(({ data }) => {
        return data?.newAccessToken ?? ''
      }),
      tap((newAccessToken: string) => {
        // Side effect: set the new access token
        newAccessToken && setAccessToken(this.storageService, newAccessToken);
      }),
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching new access token', error);
        return throwError(() => error); // Return an error Observable
      })
    );
  }
}
